export const env = {
    REACT_APP_STAGE: process.env.REACT_APP_STAGE || '',
    REACT_APP_STORYSLAB_BUCKET_TENANT_ASSETS: process.env.REACT_APP_STORYSLAB_BUCKET_TENANT_ASSETS || '',
    REACT_APP_STORYSLAB_BUCKET_PUBLIC_CONTENT: process.env.REACT_APP_STORYSLAB_BUCKET_PUBLIC_CONTENT || '',
    REACT_APP_PUBLIC_DETAILS_JSON: process.env.REACT_APP_PUBLIC_DETAILS_JSON || '',
    REACT_APP_API_GATEWAY_CLIENT: process.env.REACT_APP_API_GATEWAY_CLIENT || '',
    REACT_APP_API_GATEWAY_AUTH: process.env.REACT_APP_API_GATEWAY_AUTH || '',
    REACT_APP_PSPDFKIT_LICENSE_IOS: process.env.REACT_APP_PSPDFKIT_LICENSE_IOS || '',
    REACT_APP_PSPDFKIT_LICENSE_ANDROID: process.env.REACT_APP_PSPDFKIT_LICENSE_ANDROID || '',
    REACT_APP_PSPDFKIT_LICENSE_WINDOWS: process.env.REACT_APP_PSPDFKIT_LICENSE_WINDOWS || '',
    REACT_APP_PSPDFKIT_LICENSE_WEB: process.env.REACT_APP_PSPDFKIT_LICENSE_WEB || '',
    REACT_APP_ENABLE_LOG: process.env.REACT_APP_ENABLE_LOG || '',
    REACT_APP_LOG_LEVEL: process.env.REACT_APP_LOG_LEVEL || '',
    REACT_APP_REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI || '',
    PUBLIC_URL: process.env.PUBLIC_URL || '',
}