/* eslint-disable @typescript-eslint/typedef */
export const palette = {
  angry: '#dd3333',
  black: '#1d1d1d',
  darkGray: '#4d4d4d',
  gray: '#818181',
  lightGrey: '#939AA4',
  lighterGrey: '#999999',
  lightestGray: '#f2f2f2',
  offWhite: '#e6e6e6',
  orange: '#FBA928',
  orangeDarker: '#EB9918',
  textGrey: '#858585',
  white: '#ffffff',

  primary: '#7889e8',
  secondary: '#f8fbfc',
  success: '#4fd69c',
  info: '#fc7c5f',
  danger: '#f75676',
  light: '#bac1c8',
  dark: '#45484b',
  default: '#3c4d69',
  darker: '#292929',
};
