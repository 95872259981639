/* eslint-disable no-bitwise */

import { detect as detectBrowser } from 'detect-browser';
import { nanoid } from 'nanoid';

import { Platform } from 'react-native';
// import {
//   getBaseOsSync,
//   getDeviceTypeSync,
//   getUniqueId,
// } from 'react-native-device-info';

import {
  ApplicationTypes,
  DevicePlatforms,
  DeviceTypes,
} from '@storyslab/storyslab.common.models';

import { WorkspaceContextI } from '../context/workspace/workspace.context';
import { AuthPayload } from '../models/api.model';
import { WorkspaceContextProps } from '../models/workspace.model';

// import {
//   name as packageName,
//   version as packageVersion,
// } from '../../package.json';

import packageJson from '../../package.json';
const packageName = packageJson.name;
const packageVersion = packageJson.version;

export function generateHeaders({
  accessToken,
  applicationId,
  idToken,
  tenantId,
  userDeviceId,
}: {
  accessToken: string;
  applicationId: number;
  idToken: string;
  tenantId: number;
  userDeviceId?: number;
}): {
  Authorization: string;
  'storyslab-application-id': string;
  'storyslab-id-token': string;
  'storyslab-tenant-id': string;
  'storyslab-user-device-id': string;
} {
  const headers: any = {
    Authorization: `Bearer ${accessToken}`,
    'storyslab-application-id': String(applicationId),
    'storyslab-id-token': idToken,
    'storyslab-tenant-id': String(tenantId),
  };

  if (userDeviceId) {
    headers['storyslab-user-device-id'] = String(userDeviceId);
  }
  return headers;
}

function getDeviceString(): string {
  // Gets or generates a string in localstorage to uniquely identify browser
  const name: string = 'storyslab-device-string';
  let deviceString: any = localStorage.getItem(name);
  if (!deviceString) {
    const info: any = detectBrowser();
    const uid: string = nanoid();
    deviceString = [
      info?.type || 'unknown',
      info?.name || 'unknown',
      info?.os || 'unknown',
      uid,
    ].join('-');
  }
  localStorage.setItem(name, deviceString);
  return deviceString;
}

export function getUserDeviceDetails(): {
  applicationTypeId: ApplicationTypes;
  applicationVersion: string;
  devicePlatformId: DevicePlatforms;
  deviceTypeId: DeviceTypes;
  deviceUniqueString: string;
} {
  // Type
  // TODO: We always assume the web is on a desktop
  // const dt: string = getDeviceTypeSync();
  // HACK: Give us something to return back
  const dt: string = 'Desktop';
  let deviceTypeId: DeviceTypes = DeviceTypes.UNKNOWN;
  switch (dt) {
    case 'Handset':
      deviceTypeId = DeviceTypes.PHONE;
      break;
    case 'Tablet':
      deviceTypeId = DeviceTypes.TABLET; // TODO: Windows laptop always reports as tablet??
      break;
    case 'Desktop':
      deviceTypeId = DeviceTypes.DESKTOP;
      break;
    default:
      break;
  }

  // Platform
  const os: string = Platform.OS;
  let devicePlatformId: DevicePlatforms = DevicePlatforms.UNKNOWN;
  switch (os) {
    case 'windows':
      devicePlatformId = DevicePlatforms.WINDOWS;
      break;
    case 'android':
      devicePlatformId = DevicePlatforms.ANDROID;
      break;
    case 'ios':
      devicePlatformId = DevicePlatforms.IOS;
      break;
    case 'macos': // Todo: not totally sure about this one.. test in the future
      devicePlatformId = DevicePlatforms.MACOS;
      break;
    case 'web':
      devicePlatformId = DevicePlatforms.BROWSER;
      break;
    default:
      break;
  }

  // Device String
  // Using the CMS's client code to generate the device string
  const userDeviceString: string = getDeviceString();

  return {
    applicationTypeId: ApplicationTypes.NATIVE_APP,
    applicationVersion: `${packageName}-${packageVersion}`,
    devicePlatformId: devicePlatformId,
    deviceTypeId: deviceTypeId,
    deviceUniqueString: userDeviceString,
  };
}

export function extractAuthDetails(
  workspace: Partial<WorkspaceContextProps>,
): AuthPayload {
  if (!workspace) {
    throw new Error('Workspace Context is required');
  }

  if (!workspace?.credentials) {
    throw new Error('Workspace Context Credentials Object is required');
  }

  if (!workspace?.applicationId) {
    throw new Error('Workspace Context Application Id is required');
  }

  if (!workspace?.tenantId) {
    throw new Error('Workspace Context Tenant Id is required');
  }

  if (!workspace?.user?.deviceId) {
    throw new Error('Workspace User device id is required');
  }

  return {
    accessToken: workspace?.credentials?.accessToken,
    applicationId: workspace?.applicationId,
    idToken: workspace?.credentials.idToken,
    tenantId: workspace?.tenantId,
    userDeviceId: workspace?.user?.deviceId,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isEmpty(target: any): boolean {
  if (typeof target === 'undefined') {
    return true;
  }

  if (target && target.size !== undefined) {
    return target.size === 0;
  }

  if (target.constructor && target.constructor === Object) {
    return Object.entries(target).length === 0;
  }

  if (Array.isArray(target) || typeof target === 'string') {
    return target.length === 0;
  }

  return false;
}

export function validateEmail(email: string): boolean {
  const re: RegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function hexToRgb(
  hex: string,
): { r: number; g: number; b: number } | null {
  const result: RegExpExecArray | null =
    /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        b: parseInt(result[3], 16),
        g: parseInt(result[2], 16),
        r: parseInt(result[1], 16),
      }
    : null;
}

// WARNING: THIS IS NON-CRYPTOGRAPHIC. <-- Seriously.
export function simpleHash(
  datum: string | { [key: string]: number | string | boolean },
): string {
  let datumAsString: string = '';
  if (typeof datum === 'string') {
    datumAsString = datum;
  } else {
    for (const key in datum) {
      datumAsString = `${datumAsString}:${key}:${datum[key]}:`;
    }
  }

  let hash: number = 0;
  if (datumAsString.length === 0) {
    return hash.toString();
  }
  for (let i: number = 0; i < datumAsString.length; i++) {
    const char: number = datumAsString.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash).toString();
}
