export enum DomainNavigationEnum {
  AppCodeScreen = 'AppCodeScreen',
  LoadingScreen = 'LoadingScreen',
  WorkspaceNavigator = 'WorkspaceNavigator',
}

export enum WorkspaceNavigationEnum {
  ChooseLoginScreen = 'ChooseLoginScreen',
  GenericLoginScreen = 'GenericLoginScreen',
  SyncScreen = 'SyncScreen',
  WorkspaceRootScreen = 'WorkspaceRootScreen',
}

export enum ChromeInternalMenuTypes {
  Help = 'help',
  Settings = 'settings',
  Notifications = 'notifications',
  MyFiles = 'my-files',
  Search = 'search',
  SharePlus = 'share-plus',
  Video = 'video',
  SharePlusCurrent = 'share-plus-current-inprogress',
  SharePlusSaved = 'share-plus-saved-drafts',
  SharePlusShared = 'share-plus-previously-shared',
  SharePlusSuccess = 'share-plus-success',
  SharePlusPending = 'share-plus-pending',
}
