/* eslint-disable @typescript-eslint/no-empty-interface */

import React, { FunctionComponent, PropsWithChildren } from 'react';
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIconStyle } from '@fortawesome/react-native-fontawesome';

import Icon from '../../components/icons/Icon';
import { FontAwesomeLibraries } from '../../enums/font-awesome.enum';
import { Styles } from '../../models/styles.model';

interface Props {
  icon: IconName;
  iconLibrary?: FontAwesomeLibraries;
  isDisabled?: boolean;
  onPress?: () => void;
  size: number;
  style: FontAwesomeIconStyle;
}

const styles: Styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  } as ViewStyle,
});

export const IconButton: FunctionComponent<Props> = function IconButton(
  props: PropsWithChildren<Props>,
): JSX.Element {
  const { children, isDisabled, icon, iconLibrary, onPress, size, style } =
    props;
  return (
    <TouchableOpacity disabled={isDisabled} {...(onPress ? { onPress } : {})}>
      <Icon
        icon={icon}
        style={style}
        size={size}
        {...(iconLibrary ? { library: iconLibrary } : {})}
      />
      {children}
    </TouchableOpacity>
  );
};

export default IconButton;
